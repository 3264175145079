// Flatly 4.1.0
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$grey:     #e7e7ec !default;
$black:    #101820 !default;

$blue:      #006087 !default;
$lightblue: #93b0c5 !default;
$red:       #e74c3c !default;
$orange:    #fd7e14 !default;
$yellow:    #dddc01 !default;
$green:     #18bc9c !default;

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $lightblue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-700 !default;

// Links

$link-color:                $info !default;

// Fonts

$font-family-sans-serif:      "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base:              0.9375rem !default;

$font-weight-base:            300 !default;

// Borders

$border-radius:               0 !default;
$border-radius-lg:            0 !default;
$border-radius-sm:            0 !default;

// Tables

$table-accent-bg:             $gray-200 !default;

// Dropdowns

$dropdown-link-color:               $gray-700 !default;
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;

// Navs

$nav-link-padding-y:                .5rem !default !default;
$nav-link-padding-x:                2rem !default;
$nav-link-disabled-color:           $gray-600 !default !default;

$nav-tabs-border-color:             $gray-200 !default;

// Navbar

$navbar-padding-y:                  1rem !default;

$navbar-dark-color:                 $white !default;
$navbar-dark-hover-color:           $info !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $info !default;
$pagination-border-width:           0 !default;
$pagination-border-color:           transparent !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               darken($info, 15%) !default;
$pagination-hover-border-color:     transparent !default;

$pagination-active-bg:              $pagination-hover-bg !default;
$pagination-active-border-color:    transparent !default;

$pagination-disabled-color:         $gray-200 !default;
$pagination-disabled-bg:            lighten($info, 15%) !default;
$pagination-disabled-border-color:  transparent !default;

// Progress bars

$progress-height:                   0.625rem !default;
$progress-font-size:                0.625rem !default;

// List group

$list-group-hover-bg:               $gray-200 !default;

$list-group-disabled-bg:            $gray-200 !default;

// Close

$close-color:                       $gray-900 !default;
$close-text-shadow:                 none !default;

// Inputs

$input-border-color:                $gray-700 !default;

// Custom

$dropdown-shadow: 1px 1px 5px 1px rgba(0,0,0,.1);