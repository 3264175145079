// Flatly 4.1.0
// Bootswatch

// Selection  ==================================================================
/* Webkit */
::selection {
  background: #dddc01;
}

/* Gecko/Mozilla */
::-moz-selection {
  background: #dddc01;
}

// Root styles ===============================================================
* {
  line-height: 1.3em !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Variables ===================================================================

$web-font-path: "https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i&subset=latin-ext" !default;
@import url($web-font-path);

// Navbar ======================================================================

.bg-primary {
  .navbar-nav .active>.nav-link {
    color: $info !important;
  }
}

.bg-dark {
  background-color: $info !important;

  &.navbar-dark .navbar-nav {

    .nav-link:focus,
    .nav-link:hover,
    .active>.nav-link {
      color: $primary !important;
    }
  }
}

.navbar {
  height: 70px;
  box-shadow: 0px 0px 15px 4px #4343435c;

  .menu-logo {
    width: 200px;
    height: auto;
  }

  .navbar-nav {

    .nav-item {
      margin-right: 10px;
      position: relative;

      &:after {
        content: "";
        display: block;
        height: 2px;
        background: $warning;
        width: 0%;
        transition: all .3s ease;
        transition-delay: .2s;
      }

      &.active {

        &:after {
          width: 100%;
        }

        .nav-link {
          color: $white !important;
        }
      }

      .nav-link {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
    }

    .dropdown {

      .dropdown-toggle {
        cursor: pointer;

        &:active {
          pointer-events: none;
        }
      }

      .dropdown-menu {
        margin: 0 !important;

        .dropdown-item {
          padding-top: .35rem;
          padding-bottom: .35rem;
        }
      }

      &:hover>.dropdown-menu {
        display: block;
      }
    }
  }
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  box-shadow: $dropdown-shadow;
  border: none;
  border-top: 2px solid $white;
  padding: 0;
  margin: 0 !important;
  min-width: 100%;

  a {
    color: $primary;
    // text-transform: uppercase;

    span {
      text-transform: none !important;
    }
  }
}

// Buttons =====================================================================

.btn {
  white-space: normal;

  &-secondary,
  &-secondary:hover {
    color: $white;
  }

  &-warning,
  &-warning:hover {
    color: $body-color;
  }

  &.btn-picker {
    padding: 0.75rem 1.25rem;
  }

  &.btn-form {
    padding: 0.75rem 1rem;
  }
}

// Typography ==================================================================

// Tables ======================================================================

.table {

  &.table-fixed {
    table-layout: fixed;
  }

  .thead-dark th {
    background-color: $primary;
  }

  &-success,
  &-info,
  &-warning,
  &-danger {
    color: $white;
  }

  &-success {

    &,
    >th,
    >td {
      background-color: $success;
    }
  }

  &-info {

    &,
    >th,
    >td {
      background-color: $info;
    }
  }

  &-danger {

    &,
    >th,
    >td {
      background-color: $danger;
    }
  }

  &-warning {

    &,
    >th,
    >td {
      background-color: $warning;
    }
  }

  &-hover {

    .table-success:hover {

      &,
      >th,
      >td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {

      &,
      >th,
      >td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {

      &,
      >th,
      >td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {

      &,
      >th,
      >td {
        background-color: darken($warning, 5%);
      }
    }

  }

  &.table-allocation {

    tr {
      &.allocation-error {

        select.form-control {
          background: mix($danger, white, 15%);
        }
      }

      td {
        padding: 0 !important;

        input,
        select {
          border: none !important;

          &[readonly] {
            color: $gray-600 !important;
            background: $gray-100 !important;
          }
        }
      }
    }
  }

  &.table-asset-schedule {
    table-layout: fixed;

    tr {
      &.allocation-error {

        select.form-control {
          background: mix($danger, white, 15%);
        }
      }

      td {
        padding: 0 !important;

        input,
        select {
          border: none !important;

          &[readonly] {
            color: $gray-600;
            background: $gray-100;
          }
        }
      }
    }
  }

  &.table-asset-schedule-uk {

    table-layout: auto;

    tr {

      &.allocation-error {

        select.form-control {
          background: mix($danger, white, 15%);
        }
      }

      td {

        padding: 0 !important;

        input {
          border: none !important;
          width: 100%;
        }

        select {
          border: none !important;

          width: auto;

          &[readonly] {
            color: $gray-600;
            background: $gray-100;
          }
        }

      }
    }
  }


}



.table-allocation-time {

  td {
    padding: 2px 10px 2px 0;
    border: none;
  }
}

// Forms =======================================================================

:disabled {
  pointer-events: none;
}

.form-control {
  color: $primary;
  padding: 0.75rem 1rem;
  height: auto;

  &:disabled,
  &[readonly] {
    background: $input-disabled-bg;
  }

  &:focus {
    color: $primary;
    box-shadow: none !important;
    outline: 1px solid $info;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

select {
  &.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM0NDQiIGQ9Ik03LjQwNiA3LjgyOGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eiIgLz4KPC9zdmc+');
    background-position: 100% 50%;
    background-repeat: no-repeat;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  option:disabled {
    color: $gray-400;
  }
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}


// Navs ========================================================================

.nav-tabs {
  border-bottom: none;

  .nav-item {
    margin: 0;

    .nav-link {
      color: $primary !important;
      border-color: transparent;

      &:hover {
        border-color: $warning $warning transparent $warning !important;
      }

      &.active {
        color: $white !important;
        font-weight: 700 !important;
        background-color: $warning !important;
        border-color: $warning !important;

      }
    }
  }
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Lists ========================================================================

// Indicators ==================================================================

.close {
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.badge {
  &-secondary {
    color: $white;
  }


  &-warning {
    color: $body-color;
  }
}

.alert {
  border: none;
  color: $white;
  margin-bottom: 0 !important;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color,
  $value in $theme-colors {
    &-#{$color} {
      background-color: $value;
    }
  }

  &-light,
  &-warning {

    &,
    & a,
    & .alert-link {
      color: $body-color;
    }
  }
}

// Progress bars ===============================================================

// Containers ==================================================================

.tab-content {
  background: #fff;
}

// Tooltips =====================================================================

.tooltip {
  pointer-events: none;

  .tooltip-inner {
    text-align: left !important;
    max-width: none !important;
  }
}

[data-toggle="tooltip"] {
  cursor: default;
}

// React Typeahead ===============================================================

.rbt {

  .rbt-aux {

    .rbt-close {
      padding-right: 20px;
      margin: 0;
      color: $primary;
      font-size: 2.2rem;
    }
  }

  .rbt-menu.dropdown-menu.show {
    width: auto !important;
  }

  .rbt-input-hint {
    overflow: hidden !important;

    input[readonly] {
      @extend .form-control;
      background: transparent !important;
      width: 100% !important;
    }
  }
}

// Custom Styles ===============================================================

iframe {
  border: 0;
}

main {
  margin-top: 90px;
  flex: 1 0 auto;
}

$footer-break: 1400px;

footer {
  background: $blue;
  width: 100%;
  flex-shrink: 0;

  .footer-wrap {
    padding-right: 90px !important;

    @media (max-width: $footer-break) {
      flex-direction: column !important;
      padding-right: 0 !important;
    }
  }

  .footer-logo {
    width: 170px;
    height: auto;
  }

  .links-info {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    @media (max-width: $footer-break) {
      // flex-direction: column !important;
    }

    a {
      font-size: 18px;
      color: $white;
      margin: 0 5px;
    }
  }

  .legal-info {
    color: $white;
    font-size: 12px !important;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }

    @media (max-width: $footer-break) {
      margin-left: 0 !important;
    }

    >div {
      margin: 0 10px;

      @media (max-width: $footer-break) {
        text-align: center !important;
      }

      span {
        color: $yellow;
      }

      a {
        color: $white;
        font-size: 12px !important;
      }
    }

  }
}

.cursor {

  &.default {
    cursor: default;
  }

  &.pointer {
    cursor: pointer;
  }

  &.help {
    cursor: help;
  }
}

.btn-timeframe {
  width: 4rem;
}

.height-400 {
  height: 400px;
}

.bg-primary-light {
  background: mix($primary, white, 5%);
}

.bg-danger-light {
  background: mix($danger, white, 5%);
}

.crisp-client {
  #crisp-chatbox {
    a {
      >span:last-child {
        border: 1px solid #fff !important;
      }
    }
  }
}

.schedule-date-picker {
  width: 150px;

  &:focus,
  &:active {
    outline: none !important;
  }
}

.group-card {
  border: 1px solid;
  border-color: $gray-500;
  box-shadow: 0px 0px 8px #0061875c;
  background: #f7f7f7;
}

.chart-card {

  >.card-body {

    >.card-title {
      margin-left: 3.5rem;
      margin-bottom: 1.5rem;

      .form-check-inline {
        margin-right: 1.25rem !important;
      }

      .form-check-label {
        font-size: 0.85rem;
      }
    }
  }
}

.config-property {
  padding-top: 10px;
  padding-left: 3px;
  margin-left: 10px;
  color: $gray-700;
  font-size: 16px;

  .property-wrap {
    .edit-property {
      display: none;
    }

    &:hover {
      .edit-property {
        display: inline-block;
      }
    }

    .edit-input {
      width: 300px;
      border: none;
      outline: none;
      padding: 0;
      font-size: inherit;
      background: #ffffc9;
    }
  }

  &.config-border {
    border-bottom: 1px solid $gray-300;
  }
}

.config-key {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

.config-expandable {
  color: $primary;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.form-logo {
  left: -15px;
}

// Print =======================================================================

@media print {

  .hide-print {
    display: none !important;
  }
}